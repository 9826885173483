//media query breakpoints
//same as in bootstrap

$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px,
	xxl: 1400px
) !default;

//container max-width for each media query (defined above)
//NEED TO CHANGE, may differ from figma mockup
$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1140px,
	xxl: 1320px
) !default;

//compile into media queries
@include _assert-ascending($grid-breakpoints, '$grid-breakpoints');
@include _assert-ascending($container-max-widths, '$container-max-widths');

//defined screen size
//use this reference for future components customization / adaptation
$screen-xs: 390px;
$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xhl: 1080px;
$screen-xl: 1200px;
$screen-xxl: 1400px;
$screen-xxxl: 1680px;

$screen-hover: $screen-sm; //min screen size to display hover transitions

//0 - 575
$mobile: (
	min-width: -1,
	max-width: $screen-sm - 1
);
//575 - 991
$tablet: (
	min-width: $screen-sm,
	max-width: $screen-lg - 1
);
//992 - 1399
$laptop: (
	min-width: $screen-lg,
	max-width: $screen-xxl - 1
);
//575 - 1399
//used in font media queries
$tablet-laptop: (
	min-width: $screen-sm,
	max-width: $screen-xxl - 1
);
//1400 +
$desktop: (
	min-width: $screen-xxl,
	max-width: -1
);
//font media queries
$screens: $desktop, $tablet-laptop, $mobile; //reference -> _font-style.scss
$transition-type: cubic-bezier(0.25, 0, 0, 1); //default transition type
//$transition-type: ease-out; //default transition type
$transition-time: 300ms; //default transition time
$transition-default: color $transition-time $transition-type !default;
//base value
$baseSp: 8px;

//spacing
//use this reference for margin / padding / etc relative dimens
$m-8: $baseSp; //8 px
$m-10: $baseSp + 2; //10 px
$m-12: $baseSp + 4; //12 px
$m-16: $baseSp * 2; //16 px
$m-20: $baseSp * 2.5; //20 px
$m-24: $baseSp * 3; //24 px
$m-32: $baseSp * 4; //32 px
$m-36: $baseSp * 4.5; //36 px
$m-40: $baseSp * 5; //40 px
$m-48: $baseSp * 6; //48 px
$m-52: $baseSp * 6.5; //52 px
$m-56: $baseSp * 7; //56 px
$m-64: $baseSp * 8; //64 px
$m-72: $baseSp * 9; //72 px
$m-80: $baseSp * 10; //80 px
$m-88: $baseSp * 11; //88 px
$m-96: $baseSp * 12; //96 px
$m-100: $baseSp * 12.5; //100 px
$m-104: $baseSp * 13; //104 px
$m-112: $baseSp * 14; //112 px
$m-116: $baseSp * 14.5; //116 px
$m-120: $baseSp * 15; //120 px
$m-128: $baseSp * 16; //128 px
$m-136: $baseSp * 17; //136 px
$m-144: $baseSp * 18; //144 px
$m-152: $baseSp * 19; //152 px
$m-156: $baseSp * 19.5; //156 px
$m-160: $baseSp * 20; //160 px
$m-176: $baseSp * 22; //176 px
$m-180: $baseSp * 22.5; //180 px
$m-184: $baseSp * 23; //184 px
$m-192: $baseSp * 24; //192 px
$m-200: $baseSp * 25; //200 px
$m-256: $baseSp * 32; //256 px
