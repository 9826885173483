//global page components dimens
* {
	&::before,
	&::after,
	& {
		padding: 0;
		margin: 0;
	}
}
body {
	margin: 0;
	padding-left: 0;
	padding-right: 0;
	padding-top: 0;
}

//header -> search field
.vertical-separator {
	margin: auto 0.75em auto 1.1em;
}

body {
	&:not(.no-hover) {
		.slider-btn,
		.video-btn {
			display: none;
		}
	}
}
