@function assemblyClassName($list) {
	$className: '';
	@each $item in $list {
		$num: nth($item, 1) / 1px;
		$className: $className + '-' + $num;
	}
	@return $className;
}
@mixin unregularText($keys, $valuesMap, $mediamx) {
	$className: assemblyClassName($valuesMap);
	$counter: 1;
	@each $valuesList in $valuesMap {
		$cols: 1;
		$mixinName: nth($mediamx, $counter);
		@include includeMixin($mixinName) {
			&#{$className} {
				@each $value in $valuesList {
					$k: nth($keys, $cols);
					#{$k}: $value;
					$cols: $cols + 1;
				}
			}
		}
		$counter: $counter + 1;
	}
}

@mixin includeMixin($name) {
	@if $name == 'mobile' {
		@include mobile {
			@content;
		}
	} @else if $name == 'mobile-md' {
		@include mobile-md {
			@content;
		}
	} @else if $name == 'mobile-tablet' {
		@include mobile-tablet {
			@content;
		}
	} @else if $name == 'mobile-tablet-xhl' {
		@include mobile-tablet-xhl {
			@content;
		}
	} @else if $name == 'mobile-tablet-xl' {
		@include mobile-tablet-xl {
			@content;
		}
	} @else if $name == 'mobile-desktop-xxl' {
		@include mobile-desktop-xxl {
			@content;
		}
	} @else if $name == 'tablet' {
		@include tablet {
			@content;
		}
	} @else if $name == 'tablet-xl' {
		@include tablet-xl {
			@content;
		}
	} @else if $name == 'tablet-laptop' {
		@include tablet-laptop {
			@content;
		}
	} @else if $name == 'laptop' {
		@include laptop {
			@content;
		}
	} @else if $name == 'laptop-xl' {
		@include laptop-xl {
			@content;
		}
	} @else if $name == 'laptop-desktop' {
		@include laptop-desktop {
			@content;
		}
	} @else if $name == 'laptop-xl-desktop' {
		@include laptop-xl-desktop {
			@content;
		}
	} @else if $name == 'laptop-xxl-desktop' {
		@include laptop-xxl-desktop {
			@content;
		}
	} @else if $name == 'desktop' {
		@include desktop {
			@content;
		}
	}
}
