//link and define font
$font-path: '.././font/';
$font-base-file: 'TTHoves-Regular';
$font-demibold-file: 'TTHoves-DemiBold';
$font-extensions: ('woff2', 'woff');
$main-font: 'TTHoves';

@font-face {
	font-family: $main-font;
	src: url($font-path + $font-base-file + '.' + nth($font-extensions, 1))
			format(nth($font-extensions, 1)),
		url($font-path + $font-base-file + '.' + nth($font-extensions, 2))
			format(nth($font-extensions, 2));
	font-display: block;
	font-style: normal;
	font-weight: 400;
}

@font-face {
	font-family: $main-font;
	src: url($font-path + $font-demibold-file + '.' + nth($font-extensions, 1))
			format(nth($font-extensions, 1)),
		url($font-path + $font-demibold-file + '.' + nth($font-extensions, 2))
			format(nth($font-extensions, 2));
	font-display: block;
	font-style: normal;
	font-weight: 600;
}
