/*
p {
	@include text-gradient((260deg, #666, #000));
}
*/
@font-face {
  font-family: "TTHoves";
  src: url(".././font/TTHoves-Regular.woff2") format("woff2"), url(".././font/TTHoves-Regular.woff") format("woff");
  font-display: block;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "TTHoves";
  src: url(".././font/TTHoves-DemiBold.woff2") format("woff2"), url(".././font/TTHoves-DemiBold.woff") format("woff");
  font-display: block;
  font-style: normal;
  font-weight: 600;
}

@media (min-width: 1400px) {
  h1,
  .h1 {
    font-size: 136px;
    line-height: 136px;
    font-family: "TTHoves";
    font-weight: 600;
  }
  h2,
  .h2 {
    font-size: 92px;
    line-height: 92px;
    font-family: "TTHoves";
    font-weight: 600;
  }
  h3,
  .h3 {
    font-size: 72px;
    line-height: 72px;
    font-family: "TTHoves";
    font-weight: 600;
  }
  h4,
  .h4 {
    font-size: 52px;
    line-height: 60px;
    font-family: "TTHoves";
    font-weight: 600;
  }
  h5,
  .h5 {
    font-size: 32px;
    line-height: 40px;
    font-family: "TTHoves";
    font-weight: 600;
  }
  h6,
  .h6 {
    font-size: 28px;
    line-height: 40px;
    font-family: "TTHoves";
    font-weight: 600;
  }
}

@media (min-width: 576px) and (max-width: 1399px) {
  h1,
  .h1 {
    font-size: 96px;
    line-height: 96px;
    font-family: "TTHoves";
    font-weight: 600;
  }
  h2,
  .h2 {
    font-size: 64px;
    line-height: 64px;
    font-family: "TTHoves";
    font-weight: 600;
  }
  h3,
  .h3 {
    font-size: 48px;
    line-height: 48px;
    font-family: "TTHoves";
    font-weight: 600;
  }
  h4,
  .h4 {
    font-size: 40px;
    line-height: 52px;
    font-family: "TTHoves";
    font-weight: 600;
  }
  h5,
  .h5 {
    font-size: 24px;
    line-height: 32px;
    font-family: "TTHoves";
    font-weight: 600;
  }
  h6,
  .h6 {
    font-size: 20px;
    line-height: 32px;
    font-family: "TTHoves";
    font-weight: 600;
  }
}

@media (max-width: 575px) {
  h1,
  .h1 {
    font-size: 48px;
    line-height: 48px;
    font-family: "TTHoves";
    font-weight: 600;
  }
  h2,
  .h2 {
    font-size: 44px;
    line-height: 52px;
    font-family: "TTHoves";
    font-weight: 600;
  }
  h3,
  .h3 {
    font-size: 28px;
    line-height: 40px;
    font-family: "TTHoves";
    font-weight: 600;
  }
  h4,
  .h4 {
    font-size: 28px;
    line-height: 40px;
    font-family: "TTHoves";
    font-weight: 600;
  }
  h5,
  .h5 {
    font-size: 20px;
    line-height: 28px;
    font-family: "TTHoves";
    font-weight: 600;
  }
  h6,
  .h6 {
    font-size: 18px;
    line-height: 28px;
    font-family: "TTHoves";
    font-weight: 600;
  }
}

@media (min-width: 1400px) {
  p, p.text, .text, li {
    font-size: 20px;
    line-height: 32px;
    font-family: "TTHoves";
    font-weight: 400;
  }
  p.bold, p.text-bold {
    font-size: 20px;
    line-height: 32px;
    font-family: "TTHoves";
    font-weight: 600;
  }
  p.small, p.text-s {
    font-size: 16px;
    line-height: 24px;
    font-family: "TTHoves";
    font-weight: 400;
  }
  .text-xs {
    font-size: 14px;
    line-height: 20px;
    font-family: "TTHoves";
    font-weight: 400;
  }
  p.small-bold, p.text-s-bold {
    font-size: 16px;
    line-height: 24px;
    font-family: "TTHoves";
    font-weight: 600;
  }
  p.tiny, p.text-xs .tiny {
    font-size: 12px;
    line-height: 16px;
    font-family: "TTHoves";
    font-weight: 400;
  }
  a {
    font-size: 14px;
    line-height: 20px;
    font-family: "TTHoves";
    font-weight: 400;
    letter-spacing: 0em;
  }
  .site-navigation li a {
    font-size: 16px;
    line-height: 20px;
    font-family: "TTHoves";
    font-weight: 400;
  }
  a.bold, a.text-bold .textLinkBold {
    font-size: 14px;
    line-height: 20px;
    font-family: "TTHoves";
    font-weight: 600;
    letter-spacing: 0em;
  }
  .textLinkBoldBig {
    font-size: 20px;
    line-height: 32px;
    font-family: "TTHoves";
    font-weight: 600;
  }
  a.underline {
    font-size: 16px;
    line-height: 24px;
    font-family: "TTHoves";
    font-weight: 400;
  }
  .link {
    font-size: 16px;
    line-height: 24px;
    font-family: "TTHoves";
    font-weight: 400;
  }
  .form-group, input, textarea {
    font-size: 20px;
    line-height: 32px;
    font-family: "TTHoves";
    font-weight: 400;
    letter-spacing: 0.01em;
  }
  .text-16-bold {
    font-size: 16px;
    line-height: 24px;
    font-family: "TTHoves";
    font-weight: 600;
  }
  .h2-h3 {
    font-size: 92px;
    line-height: 92px;
    font-family: "TTHoves";
    font-weight: 600;
  }
  .video-btn p, #cursor p {
    font-size: 12px;
    line-height: 16px;
    font-family: "TTHoves";
    font-weight: 600;
    letter-spacing: 0em;
  }
  .footer .site-navigation li a {
    font-size: 20px;
    line-height: 32px;
    font-family: "TTHoves";
    font-weight: 400;
  }
}

@media (min-width: 576px) and (max-width: 1399px) {
  p, p.text, .text, li {
    font-size: 16px;
    line-height: 24px;
    font-family: "TTHoves";
    font-weight: 400;
  }
  p.bold, p.text-bold {
    font-size: 16px;
    line-height: 24px;
    font-family: "TTHoves";
    font-weight: 600;
  }
  p.small, p.text-s {
    font-size: 14px;
    line-height: 20px;
    font-family: "TTHoves";
    font-weight: 400;
  }
  .text-xs {
    font-size: 12px;
    line-height: 16px;
    font-family: "TTHoves";
    font-weight: 400;
  }
  p.small-bold, p.text-s-bold {
    font-size: 14px;
    line-height: 20px;
    font-family: "TTHoves";
    font-weight: 600;
  }
  p.tiny, p.text-xs .tiny {
    font-size: 12px;
    line-height: 16px;
    font-family: "TTHoves";
    font-weight: 400;
  }
  a {
    font-size: 12px;
    line-height: 16px;
    font-family: "TTHoves";
    font-weight: 400;
    letter-spacing: 0em;
  }
  .site-navigation li a {
    font-size: 14px;
    line-height: 20px;
    font-family: "TTHoves";
    font-weight: 400;
  }
  a.bold, a.text-bold .textLinkBold {
    font-size: 12px;
    line-height: 16px;
    font-family: "TTHoves";
    font-weight: 600;
    letter-spacing: 0em;
  }
  .textLinkBoldBig {
    font-size: 16px;
    line-height: 24px;
    font-family: "TTHoves";
    font-weight: 600;
  }
  a.underline {
    font-size: 14px;
    line-height: 20px;
    font-family: "TTHoves";
    font-weight: 400;
  }
  .link {
    font-size: 16px;
    line-height: 20px;
    font-family: "TTHoves";
    font-weight: 400;
  }
  .form-group, input, textarea {
    font-size: 16px;
    line-height: 20px;
    font-family: "TTHoves";
    font-weight: 400;
    letter-spacing: 0.01em;
  }
  .text-16-bold {
    font-size: 16px;
    line-height: 24px;
    font-family: "TTHoves";
    font-weight: 600;
  }
  .h2-h3 {
    font-size: 48px;
    line-height: 48px;
    font-family: "TTHoves";
    font-weight: 600;
  }
  .video-btn p, #cursor p {
    font-size: 12px;
    line-height: 16px;
    font-family: "TTHoves";
    font-weight: 600;
    letter-spacing: 0em;
  }
  .footer .site-navigation li a {
    font-size: 16px;
    line-height: 24px;
    font-family: "TTHoves";
    font-weight: 400;
  }
}

@media (max-width: 575px) {
  p, p.text, .text, li {
    font-size: 16px;
    line-height: 24px;
    font-family: "TTHoves";
    font-weight: 400;
  }
  p.bold, p.text-bold {
    font-size: 16px;
    line-height: 24px;
    font-family: "TTHoves";
    font-weight: 600;
  }
  p.small, p.text-s {
    font-size: 14px;
    line-height: 20px;
    font-family: "TTHoves";
    font-weight: 400;
  }
  .text-xs {
    font-size: 12px;
    line-height: 16px;
    font-family: "TTHoves";
    font-weight: 400;
  }
  p.small-bold, p.text-s-bold {
    font-size: 14px;
    line-height: 20px;
    font-family: "TTHoves";
    font-weight: 600;
  }
  p.tiny, p.text-xs .tiny {
    font-size: 12px;
    line-height: 16px;
    font-family: "TTHoves";
    font-weight: 400;
  }
  a {
    font-size: 12px;
    line-height: 16px;
    font-family: "TTHoves";
    font-weight: 400;
    letter-spacing: 0em;
  }
  .site-navigation li a {
    font-size: 12px;
    line-height: 16px;
    font-family: "TTHoves";
    font-weight: 400;
  }
  a.bold, a.text-bold .textLinkBold {
    font-size: 12px;
    line-height: 16px;
    font-family: "TTHoves";
    font-weight: 600;
    letter-spacing: 0em;
  }
  .textLinkBoldBig {
    font-size: 16px;
    line-height: 24px;
    font-family: "TTHoves";
    font-weight: 600;
  }
  a.underline {
    font-size: 14px;
    line-height: 20px;
    font-family: "TTHoves";
    font-weight: 400;
  }
  .link {
    font-size: 14px;
    line-height: 16px;
    font-family: "TTHoves";
    font-weight: 400;
  }
  .form-group, input, textarea {
    font-size: 14px;
    line-height: 16px;
    font-family: "TTHoves";
    font-weight: 400;
    letter-spacing: 0.01em;
  }
  .text-16-bold {
    font-size: 16px;
    line-height: 24px;
    font-family: "TTHoves";
    font-weight: 600;
  }
  .h2-h3 {
    font-size: 28px;
    line-height: 40px;
    font-family: "TTHoves";
    font-weight: 600;
  }
  .video-btn p, #cursor p {
    font-size: 12px;
    line-height: 16px;
    font-family: "TTHoves";
    font-weight: 600;
    letter-spacing: 0em;
  }
  .footer .site-navigation li a {
    font-size: 16px;
    line-height: 24px;
    font-family: "TTHoves";
    font-weight: 400;
  }
}

@media only screen and (max-width: 575px) {
  .text-14-16-12-14 {
    font-size: 14px;
    line-height: 16px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 991px) {
  .text-14-16-12-14 {
    font-size: 16px;
    line-height: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1399px) {
  .text-14-16-12-14 {
    font-size: 12px;
    line-height: 16px;
  }
}

@media only screen and (min-width: 1400px) {
  .text-14-16-12-14 {
    font-size: 14px;
    line-height: 16px;
  }
}

* {
  scrollbar-width: thin;
}

*::before, *::after, * {
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  font-family: "TTHoves", sans-serif;
  background-color: transparent;
}

*::before, *::after {
  display: block;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

*::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

html {
  scroll-behavior: smooth;
}

body {
  overflow-y: overlay;
}

body * :is(h1, h2, h3, h4, h5, h6, p, a, span) {
  transition: color 300ms cubic-bezier(0.25, 0, 0, 1);
}

body.overflow {
  overflow: hidden;
}

ul,
ol {
  list-style: none;
}

a {
  text-decoration: none;
}

a,
button,
input[type='submit'],
.button {
  display: block;
  border: none;
  outline: none;
}

input,
textarea {
  resize: none;
  outline: none;
  border: none;
}

input:focus,
textarea:focus {
  outline: none;
}

img {
  display: block;
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
  -webkit-user-drag: none;
  user-select: none;
}

.uppercase {
  text-transform: uppercase;
}

.transparent {
  opacity: 0;
}

.ls-0,
.ls-0 * {
  letter-spacing: 0;
}

.dot {
  position: relative;
  padding: 0 4px;
}

.dot::after {
  content: '';
  position: absolute;
  border-radius: 50%;
  width: 6px;
  height: 6px;
}

.dot-left::after {
  left: -3px;
}

.dot-right::after {
  right: -0.6em;
}

.dot-top::after {
  top: 0;
}

.dot-bottom::after {
  bottom: 0;
}

.vertical-separator {
  height: 1.7em;
  width: 1px;
}

main {
  padding-top: 91px;
}

main:not(.no-margin) {
  margin: 100px auto 200px;
}

main .container {
  max-width: 1920px;
  padding: 0 2rem;
}

@media only screen and (max-width: 1399px) {
  main:not(.no-margin) {
    margin: 80px auto 120px;
  }
}

@media only screen and (max-width: 991px) {
  main {
    padding-top: 86px;
  }
  main:not(.no-margin) {
    margin: 64px auto calc(104px - 4px);
  }
}

@media only screen and (max-width: 575px) {
  main {
    padding-top: 74px;
  }
  main:not(.no-margin) {
    margin-top: 16px;
  }
  main .container {
    padding: 0 1rem;
  }
}

.page__heading {
  display: flex;
  align-items: center;
  margin-bottom: 104px;
  overflow: hidden;
}

.page__heading-info {
  margin-left: 40px;
}

.page__heading.border-bottom {
  position: relative;
}

.page__heading.border-bottom::after {
  content: '';
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2px;
}

@media only screen and (max-width: 1399px) {
  .page__heading {
    margin-bottom: 80px;
  }
}

@media only screen and (max-width: 1079px) {
  .page__heading {
    margin-bottom: 72px;
  }
}

@media only screen and (max-width: 767px) {
  .page__heading {
    margin-bottom: 64px;
  }
  .page__heading-info {
    margin-left: 32px;
  }
  .page__heading-info br {
    display: none;
  }
}

@media only screen and (max-width: 575px) {
  .page__heading {
    margin-bottom: 32px;
  }
  .page__heading-info {
    margin-left: 16px;
  }
}

.tablinks {
  position: relative;
  margin-bottom: 80px;
}

.tablinks ul {
  display: flex;
  flex-wrap: wrap;
}

.tablinks li {
  position: relative;
  outline: none;
  padding-bottom: 24px;
  margin-right: 48px;
}

.tablinks li:last-child {
  margin-right: 0;
}

.tablinks li:after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 2px;
}

.tablinks:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
}

@media only screen and (max-width: 575px) {
  .artists .page__heading {
    margin-bottom: 16px;
  }
  .artists .tablinks li {
    padding-top: 16px;
  }
  .artists .tablinks span {
    font-size: 16px;
    line-height: 1.5;
  }
}

.tablist {
  margin-bottom: 160px;
}

.tablist__mobile {
  display: none;
}

.tablist__mobile__nav {
  margin-bottom: 32px;
  overflow-y: auto;
  display: flex;
}

.tablist__mobile__nav::-webkit-scrollbar {
  display: none;
}

.tablist__mobile__nav li {
  font-size: 18px;
  transition: 300ms cubic-bezier(0.25, 0, 0, 1);
  margin-right: 32px;
}

.tablist__mobile__nav li.active {
  font-weight: bold;
}

.tablist__mobile__elements li a {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: calc(16px - 4px);
}

.tablist__mobile__elements li a:after {
  opacity: 0;
  padding: 5px;
}

.tablist__mobile__elements li a:hover::after {
  opacity: 1;
}

.tablist__desktop {
  -moz-column-count: 4;
  -moz-column-gap: 24px;
  -webkit-column-count: 4;
  -webkit-column-gap: 24px;
  column-count: 4;
  column-gap: 24px;
}

.tablist__desktop li a {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
  letter-spacing: 0;
  grid-gap: 0.4em;
}

.tablist__desktop li a:after {
  opacity: 0;
  transition: all 300ms cubic-bezier(0.25, 0, 0, 1);
  padding: 5px;
}

.tablist__desktop li a:hover::after {
  opacity: 1;
}

.tabcontent {
  display: none;
}

@media only screen and (max-width: 575px) {
  .tabcontent {
    overflow: hidden;
  }
}

.tabcontent.active {
  display: grid;
}

.tabcontent__col-2, .tabcontent__col-3, .tabcontent__col-about, .tabcontent__col-2, .tabcontent__col-3, .tabcontent__col-about {
  display: grid;
}

.tabcontent__col-2, .tabcontent__col-2 {
  grid-template-columns: repeat(2, 1fr);
  gap: 32px;
}

.tabcontent__col-3, .tabcontent__col-3 {
  grid-template-columns: repeat(3, 1fr);
  gap: 104px 32px;
  align-items: stretch;
}

.tabcontent__col-about, .tabcontent__col-about {
  grid-template-columns: 6fr 1fr 5fr;
  gap: 32px;
}

.tabcontent__single {
  display: grid;
  align-items: flex-start;
  grid-template-areas: 'content sitebar' 'video__wrapper video__wrapper';
  grid-template-columns: 6fr 4fr;
}

.tabcontent__single .content {
  grid-area: content;
  margin-right: 16.66%;
}

.tabcontent__single .sitebar {
  grid-area: sitebar;
}

@media only screen and (min-width: 768px) {
  .tabcontent__single .sitebar {
    position: sticky;
    top: 20vh;
  }
}

.tabcontent__single .video__wrapper {
  grid-area: video__wrapper;
}

.tabcontent__item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media only screen and (hover: hover) and (pointer: fine) {
  .tabcontent__item svg {
    opacity: 0;
  }
  .tabcontent__item:hover svg {
    opacity: 1;
    stroke: var(--textColor);
  }
}

.tabcontent__item.photo-anim:not(.photo-anim-on) img {
  height: 50%;
}

.tabcontent__item__img {
  display: flex;
  justify-content: center;
}

.tabcontent__item__img .figure-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tabcontent__item__img figure {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  max-height: 100%;
}

.tabcontent__item__img figure img {
  object-fit: cover;
  grid-column: 1 / -1;
  grid-row: 1 / -1;
}

.tabcontent__item__info {
  margin: 32px 0 auto;
  padding-bottom: 32px;
}

.tabcontent__item__info h1,
.tabcontent__item__info h2,
.tabcontent__item__info h3,
.tabcontent__item__info h4,
.tabcontent__item__info h5,
.tabcontent__item__info h6 {
  margin-bottom: 8px;
}

.tabcontent__item__info p:not(:last-child) {
  margin-bottom: calc(4px);
}

.tabcontent__item__info-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

.tabcontent__item__info-link h1,
.tabcontent__item__info-link h2,
.tabcontent__item__info-link h3,
.tabcontent__item__info-link h4,
.tabcontent__item__info-link h5,
.tabcontent__item__info-link h6 {
  margin-right: 8px;
  margin-bottom: 0;
}

.tabcontent__item__info-link svg {
  transition: all 300ms cubic-bezier(0.25, 0, 0, 1);
}

.tabcontent__item__info-artist {
  margin: 32px 0 -12px;
}

.tabcontent__item::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background: var(--textColor);
  transition: 0.7s;
  transform-origin: left;
}

@media only screen and (max-width: 1399px) {
  .tablinks {
    margin-bottom: 64px;
  }
  .tablinks li {
    padding-bottom: 16px;
    margin-right: 32px;
  }
  .tablist {
    margin-bottom: 104px;
  }
  .tabcontent__col-3 {
    gap: 80px 32px;
  }
  .tabcontent__item__info {
    margin: 16px 0 auto;
    padding-bottom: 16px;
  }
  .tabcontent__item__info-artist {
    margin: 32px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .tablist {
    margin-bottom: 80px;
  }
  .tablist__desktop {
    -moz-column-count: 2;
    -moz-column-gap: 16px;
    -webkit-column-count: 2;
    -webkit-column-gap: 16px;
    column-count: 2;
    column-gap: 16px;
  }
  .tablist__desktop li a {
    margin-bottom: calc(16px - 4px);
  }
  .tabcontent__col-2 {
    grid-template-columns: 1fr;
    gap: 80px;
  }
  .tabcontent__col-3 {
    grid-template-columns: repeat(2, 1fr);
  }
  .tabcontent__col-about {
    grid-template-columns: 1fr;
  }
  .tabcontent__col-about .languages {
    order: 1;
  }
  .tabcontent__col-about .slider {
    order: 2;
  }
  .tabcontent__col-about .about__content {
    order: 3;
  }
  .tabcontent__single {
    grid-template-columns: 5fr 3fr;
    gap: 32px;
  }
  .tabcontent__single .content {
    margin-right: 0;
  }
  .tabcontent__item__info-artist {
    margin: 16px 0 0;
  }
}

@media only screen and (max-width: 575px) {
  .tablinks {
    margin-bottom: 32px;
  }
  .tablinks li {
    margin-right: 16px;
  }
  .tablinks.sticky {
    z-index: 99;
    width: calc(100% - 32px);
  }
  .tablist {
    margin-bottom: 40px;
  }
  .tablist__desktop {
    display: none;
  }
  .tablist__mobile {
    display: block;
  }
  .tablist__mobile__elements {
    -moz-column-count: 1;
    -moz-column-gap: 0;
    -webkit-column-count: 1;
    -webkit-column-gap: 0;
    column-count: 1;
    column-gap: 0;
  }
  .tabcontent__col-2 {
    gap: 40px;
  }
  .tabcontent__col-3 {
    grid-template-columns: 1fr;
    gap: 40px;
  }
  .tabcontent__single {
    grid-template-areas: 'content' 'video__wrapper' 'sitebar';
    grid-template-columns: 1fr;
  }
}

.tabcontent__item__img {
  overflow: hidden;
}

.tabcontent__item p,
.tabcontent__item a {
  overflow: hidden;
}

.tabcontent__item p span,
.tabcontent__item a span {
  display: block;
}

.video__about span {
  transform: translateY(100%);
  transition: 0.7s;
}

.video__about.easy-fadeNot-on span {
  transform: translateY(0);
  transition: 0.7s;
}

.video__about {
  overflow: hidden;
}

.video__about span {
  display: block;
}

*::before, *::after, * {
  padding: 0;
  margin: 0;
}

body {
  margin: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
}

.vertical-separator {
  margin: auto 0.75em auto 1.1em;
}

body:not(.no-hover) .slider-btn,
body:not(.no-hover) .video-btn {
  display: none;
}

.page-404 {
  overflow: hidden;
  perspective: 1500px;
}

.page-404 .container {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  padding: 32px;
}

@supports (height: calc(var(--vh, 1vh) * 100)) {
  .page-404 .container {
    height: calc(var(--vh, 1vh) * 100);
  }
}

@media only screen and (max-width: 575px) {
  .page-404 .container {
    padding: 16px;
  }
}

.page-404 .container .not-found-title {
  height: fit-content;
  width: fit-content;
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.page-404 .container .button-404__mobile {
  z-index: 9999;
  display: none;
  position: absolute;
  bottom: 120px;
  right: 120px;
  height: 132px;
  width: 132px;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 16px;
  border-radius: 50%;
  letter-spacing: initial;
  text-transform: uppercase;
}

@media only screen and (max-width: 991px) {
  .page-404 .container .button-404__mobile {
    display: flex;
  }
}

@media only screen and (max-width: 575px) {
  .page-404 .container .button-404__mobile {
    bottom: 16%;
    right: 32px;
  }
}

.page-404 .container .text-404 {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: fit-content;
  width: fit-content;
  font-size: 168px;
  line-height: 1;
}

@media only screen and (min-width: 1400px) {
  .page-404 .container .text-404 {
    font-size: 332px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1399px) {
  .page-404 .container .text-404 {
    font-style: 300px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 991px) {
  .page-404 .container .text-404 {
    font-style: 200px;
  }
}

.page-404 .container .header__logo {
  position: absolute;
  top: 32px;
  left: 0;
  right: 0;
}

.page-404 .container .header__logo a {
  height: 24px;
}

@media only screen and (max-width: 575px) {
  .page-404 .container .header__logo {
    display: none;
  }
}

.page-404 .container .img-404 {
  transition: 400ms cubic-bezier(0.25, 0, 0, 1);
  transform-style: preserve-3d;
  position: absolute;
  z-index: 5;
}

.page-404 .container .img-404-1 {
  top: 2%;
  left: 2%;
  width: 8.13%;
  min-width: 92px;
}

.page-404 .container .img-404-2 {
  top: 19%;
  left: 16.5%;
  width: 13.3%;
  min-width: 184px;
}

@media only screen and (max-width: 575px) {
  .page-404 .container .img-404-2 {
    min-width: 76px;
  }
}

.page-404 .container .img-404-3 {
  top: 9%;
  right: 37%;
  width: 5.54%;
  min-width: 72px;
}

@media only screen and (max-width: 991px) {
  .page-404 .container .img-404-3 {
    display: none;
  }
}

.page-404 .container .img-404-4 {
  top: 9%;
  right: 2%;
  width: 16.3%;
  min-width: 184px;
}

@media only screen and (max-width: 575px) {
  .page-404 .container .img-404-4 {
    min-width: 115px;
  }
}

.page-404 .container .img-404-5 {
  top: 41%;
  right: 13%;
  width: 11.8%;
  min-width: 124px;
}

@media only screen and (max-width: 767px) {
  .page-404 .container .img-404-5 {
    display: none;
  }
}

.page-404 .container .img-404-6 {
  bottom: 10%;
  left: 2%;
  width: 14.66%;
  min-width: 216px;
}

@media only screen and (max-width: 575px) {
  .page-404 .container .img-404-6 {
    min-width: 128px;
  }
}

.page-404 .container .img-404-7 {
  bottom: 2.5%;
  left: 21%;
  width: 5%;
  min-width: 72px;
}

@media only screen and (max-width: 767px) {
  .page-404 .container .img-404-7 {
    display: none;
  }
}

.page-404 .container .img-404-8 {
  bottom: 2%;
  right: 10%;
  width: 16.3%;
  min-width: 224px;
}

@media only screen and (max-width: 575px) {
  .page-404 .container .img-404-8 {
    min-width: 90px;
  }
}
