$defaultHweight: 600;
$headingMobile: (
	h1: (
		48px,
		48px,
		$main-font,
		$defaultHweight
	),
	h2: (
		44px,
		52px,
		$main-font,
		$defaultHweight
	),
	h3: (
		28px,
		40px,
		$main-font,
		$defaultHweight
	),
	h4: (
		28px,
		40px,
		$main-font,
		$defaultHweight
	),
	h5: (
		20px,
		28px,
		$main-font,
		$defaultHweight
	),
	h6: (
		18px,
		28px,
		$main-font,
		$defaultHweight
	)
);

$headingLaptop: (
	h1: (
		96px,
		96px,
		$main-font,
		$defaultHweight
	),
	h2: (
		64px,
		64px,
		$main-font,
		$defaultHweight
	),
	h3: (
		48px,
		48px,
		$main-font,
		$defaultHweight
	),
	h4: (
		40px,
		52px,
		$main-font,
		$defaultHweight
	),
	h5: (
		24px,
		32px,
		$main-font,
		$defaultHweight
	),
	h6: (
		20px,
		32px,
		$main-font,
		$defaultHweight
	)
);

$headingDesktop: (
	h1: (
		136px,
		136px,
		$main-font,
		$defaultHweight
	),
	h2: (
		92px,
		92px,
		$main-font,
		$defaultHweight
	),
	h3: (
		72px,
		72px,
		$main-font,
		$defaultHweight
	),
	h4: (
		52px,
		60px,
		$main-font,
		$defaultHweight
	),
	h5: (
		32px,
		40px,
		$main-font,
		$defaultHweight
	),
	h6: (
		28px,
		40px,
		$main-font,
		$defaultHweight
	)
);

$headingStyle: $headingDesktop, $headingLaptop, $headingMobile;

@include heading($headingStyle, $screens);

//add your variable with matching pattern:
// (font-size, line-height, font-family, font-weight)
//structure
//$var: (18px, 18px, 'Arial', 400), (14px, 14px, 'Arial', 400), (10px, 10px, 'Arial', 400)
//Order:
//$var: (Desktop Style), (Tablet/Laptop Style), (Mobile Style)
$textRegular: (20px, 32px, $main-font, 400), (16px, 24px, $main-font, 400),
	(16px, 24px, $main-font, 400);
$textRegularBold: (20px, 32px, $main-font, 600), (16px, 24px, $main-font, 600),
	(16px, 24px, $main-font, 600);
$textSmall: (16px, 24px, $main-font, 400), (14px, 20px, $main-font, 400),
	(14px, 20px, $main-font, 400);
$textExtraSmall: (14px, 20px, $main-font, 400), (12px, 16px, $main-font, 400),
	(12px, 16px, $main-font, 400);
$textSmallBold: (16px, 24px, $main-font, 600), (14px, 20px, $main-font, 600),
	(14px, 20px, $main-font, 600);
$textTiny: (12px, 16px, $main-font, 400), (12px, 16px, $main-font, 400),
	(12px, 16px, $main-font, 400);
$textTinyBold: (12px, 16px, $main-font, 600, 0em),
	(12px, 16px, $main-font, 600, 0em), (12px, 16px, $main-font, 600, 0em);
$textLink: (14px, 20px, $main-font, 400, 0em),
	(12px, 16px, $main-font, 400, 0em), (12px, 16px, $main-font, 400, 0em);
$textLinkNav: (16px, 20px, $main-font, 400), (14px, 20px, $main-font, 400),
	(12px, 16px, $main-font, 400);
$textLinkBold: (14px, 20px, $main-font, 600, 0em),
	(12px, 16px, $main-font, 600, 0em), (12px, 16px, $main-font, 600, 0em);
$textLinkBoldBig: (20px, 32px, $main-font, 600), (16px, 24px, $main-font, 600),
	(16px, 24px, $main-font, 600);
$textLinkUnderline: (16px, 24px, $main-font, 400), (14px, 20px, $main-font, 400),
	(14px, 20px, $main-font, 400);
$textLinkHeader: (16px, 24px, $main-font, 400), (16px, 20px, $main-font, 400),
	(14px, 16px, $main-font, 400);
$textForm: (20px, 32px, $main-font, 400, 0.01em),
	(16px, 20px, $main-font, 400, 0.01em), (14px, 16px, $main-font, 400, 0.01em);
$text-16: (16px, 24px, $main-font, 400), (16px, 24px, $main-font, 400),
	(16px, 24px, $main-font, 400);
$text-16-bold: (16px, 24px, $main-font, 600), (16px, 24px, $main-font, 600),
	(16px, 24px, $main-font, 600);

$h2-h3: map-get($headingDesktop, 'h2'), map-get($headingLaptop, 'h3'),
	map-get($headingMobile, 'h3');
//add text-styling variable here:
//Pattern:
//'selectors, .selector': $var
//first part convert to CSS directly:
// 'p, div > span' will transform into  p, div > span { ... }
$textStyle: (
	'p, p.text, .text, li': $textRegular,
	'p.bold, p.text-bold': $textRegularBold,
	'p.small, p.text-s': $textSmall,
	'.text-xs': $textExtraSmall,
	'p.small-bold, p.text-s-bold': $textSmallBold,
	'p.tiny, p.text-xs .tiny': $textTiny,
	'a': $textLink,
	'.site-navigation li a': $textLinkNav,
	'a.bold, a.text-bold .textLinkBold': $textLinkBold,
	'.textLinkBoldBig': $textLinkBoldBig,
	'a.underline': $textLinkUnderline,
	'.link': $textLinkHeader,
	'.form-group, input, textarea': $textForm,
	'.text-16-bold': $text-16-bold,
	'.h2-h3': $h2-h3,
	'.video-btn p, #cursor p': $textTinyBold,
	'.footer .site-navigation li a': $textRegular //before: "text-16"
);

//apply mixin
@include texting($textStyle, $screens);

//unregular text style
//name pattern: .text-mobile-tablet-laptop-desktop
$textParams: (font-size, line-height);
$mediaMixins: (mobile, tablet, laptop, desktop);

$text-14-16-12-14: ((14px, 16px), (16px, 20px), (12px, 16px), (14px, 16px));
.text {
	@include unregularText($textParams, $text-14-16-12-14, $mediaMixins);
}
