@import 'asset-helpers-base';
@import 'asset-helpers';
$vh100: calc(var(--vh, 1vh) * 100);

.page-404 {
	overflow: hidden;
	perspective: 1500px;
	.container {
		position: fixed;
		top: 0;
		left: 0;
		height: 100vh;
		@supports (height: #{$vh100}) {
			height: #{$vh100};
		}
		width: 100vw;
		padding: $m-32;
		@include mobile {
			padding: $m-16;
		}
		.not-found-title {
			height: fit-content;
			width: fit-content;
			position: absolute;
			margin: auto;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
		}
		.button-404__mobile {
			z-index: 9999;
			display: none;
			position: absolute;
			bottom: $m-120;
			right: $m-120;
			height: 132px;
			width: 132px;
			align-items: center;
			justify-content: center;
			text-align: center;
			padding: $m-16;
			border-radius: 50%;
			letter-spacing: initial;
			text-transform: uppercase;

			// background-color: map-get($colors, 'text');
			// color: map-get($colors, 'bg');
			@include mobile-tablet {
				display: flex;
			}
			@include mobile {
				bottom: 16%;
				right: 32px;
			}
		}
		.text-404 {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			margin: auto;
			height: fit-content;
			width: fit-content;
			font-size: 168px;
			line-height: 1;
			@include desktop {
				font-size: 332px;
			}
			@include laptop {
				font-style: 300px;
			}
			@include tablet {
				font-style: 200px;
			}
		}
		.header__logo {
			position: absolute;
			top: $m-32;
			left: 0;
			right: 0;
			a {
				height: $m-24;
			}
			@include mobile {
				display: none;
			}
		}
		.img-404 {
			transition: 400ms cubic-bezier(0.25, 0, 0, 1);
			transform-style: preserve-3d;
			position: absolute;
			z-index: 5;
			&-1 {
				top: 2%;
				left: 2%;
				width: 8.13%;
				min-width: 92px;
			}
			&-2 {
				top: 19%;
				left: 16.5%;
				width: 13.3%;
				min-width: 184px;
				@include mobile {
					min-width: 76px;
				}
			}
			&-3 {
				top: 9%;
				right: 37%;
				width: 5.54%;
				min-width: 72px;
				@include mobile-tablet {
					display: none;
				}
			}
			&-4 {
				top: 9%;
				right: 2%;
				width: 16.3%;
				min-width: 184px;
				@include mobile {
					min-width: 115px;
				}
			}
			&-5 {
				top: 41%;
				right: 13%;
				width: 11.8%;
				min-width: 124px;
				@include mobile-md {
					display: none;
				}
			}
			&-6 {
				bottom: 10%;
				left: 2%;
				width: 14.66%;
				min-width: 216px;
				@include mobile {
					min-width: 128px;
				}
			}
			&-7 {
				bottom: 2.5%;
				left: 21%;
				width: 5%;
				min-width: 72px;
				@include mobile-md {
					display: none;
				}
			}
			&-8 {
				bottom: 2%;
				right: 10%;
				width: 16.3%;
				min-width: 224px;
				@include mobile {
					min-width: 90px;
				}
			}
		}
	}
}
