//global page styling
$defaultTransitionDelay: 0.7s;
$defaultType: ease-out;
$defaultTransition: 0.7s;
$vh100: calc(var(--vh, 1vh) * 100);

* {
	&::before,
	&::after,
	& {
		box-sizing: border-box;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: contain;
		font-family: $main-font, sans-serif;
		background-color: transparent;
	}
	&::before,
	&::after {
		display: block;
	}
	scrollbar-width: thin;
	&::-webkit-scrollbar {
		width: 8px;
		height: 8px;
	}
	&::-webkit-scrollbar-track {
		box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	}
	&::-webkit-scrollbar-thumb {
		background-color: darkgrey;
		outline: 1px solid slategrey;
	}
}
html {
	scroll-behavior: smooth;
}
body {
	overflow-y: overlay;
	* {
		:is(h1, h2, h3, h4, h5, h6, p, a, span) {
			transition: $transition-default;
		}
	}
	&.overflow {
		overflow: hidden;
	}
}
ul,
ol {
	list-style: none;
}
a {
	text-decoration: none;
}

a,
button,
input[type='submit'],
.button {
	display: block;
	border: none;
	outline: none;
}

input,
textarea {
	resize: none;
	outline: none;
	border: none;
	&:focus {
		outline: none;
	}
}

img {
	display: block;
	object-fit: contain;
	max-width: 100%;
	max-height: 100%;
	-webkit-user-drag: none;
	user-select: none;
}
.uppercase {
	text-transform: uppercase;
}
.transparent {
	opacity: 0;
}
.ls-0 {
	&,
	& * {
		letter-spacing: 0;
	}
}
//---- Header -> Search ----
//--------------------------
.dot {
	position: relative;
	padding: 0 $m-8 / 2;
	&::after {
		content: '';
		position: absolute;
		border-radius: 50%;
		width: 6px;
		height: 6px;
	}
	&-left {
		&::after {
			left: -3px;
		}
	}
	&-right {
		&::after {
			right: -0.6em;
		}
	}
	&-top {
		&::after {
			top: 0;
		}
	}
	&-bottom {
		&::after {
			bottom: 0;
		}
	}
}

.vertical-separator {
	height: 1.7em;
	width: 1px;
}
//------ Header -> Search End -------
//-----------------------------------

//------ Page Outside Margins -------
//-----------------------------------
main {
	&:not(.no-margin) {
		margin: $m-100 auto $m-200;
	}
	.container {
		max-width: 1920px;
		padding: 0 2rem;
	}

	padding-top: 91px;

	@include mobile-desktop-xxl {
		&:not(.no-margin) {
			margin: $m-80 auto $m-120;
		}
	}

	@include mobile-tablet {
		&:not(.no-margin) {
			margin: $m-64 auto calc(#{$m-104} - #{$m-8 / 2});
		}
		padding-top: 86px;
	}

	@include mobile {
		&:not(.no-margin) {
			margin-top: $m-16;
		}
		.container {
			padding: 0 1rem;
		}
		padding-top: 74px;
	}
}

//---- Page Outside Margins End -----
//-----------------------------------

//-----------------------------------
//--------- Page Heading ------------

.page__heading {
	display: flex;
	align-items: center;
	margin-bottom: $m-104;
	overflow: hidden;
	&-info {
		margin-left: $m-40;
	}
	&.border {
		&-bottom {
			position: relative;
			&::after {
				content: '';
				position: absolute;
				bottom: 0;
				width: 100%;
				height: 2px;
			}
		}
	}
}

@include mobile-desktop-xxl {
	.page__heading {
		margin-bottom: $m-80;
	}
}
@include mobile-tablet-xhl {
	.page__heading {
		margin-bottom: $m-72;
	}
}
@include mobile-md {
	.page__heading {
		margin-bottom: $m-64;
		&-info {
			margin-left: $m-32;
			br {
				display: none;
			}
		}
	}
}
@include mobile {
	.page__heading {
		margin-bottom: $m-32;
		&-info {
			margin-left: $m-16;
		}
	}
}
//------- Page Heading End ----------
//-----------------------------------

//------ Page Tabs & Content --------
//-----------------------------------
.tablinks {
	position: relative;
	margin-bottom: $m-80;
	ul {
		display: flex;
		flex-wrap: wrap;
	}
	li {
		position: relative;
		outline: none;
		padding-bottom: $m-24;
		margin-right: $m-48;

		&:last-child {
			margin-right: 0;
		}
		&:after {
			content: '';
			position: absolute;
			bottom: -2px;
			left: 0;
			width: 100%;
			height: 2px;
		}
	}
	&:after {
		content: '';
		// z-index: -1;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 1px;
	}
}

@include mobile {
	.artists {
		.page__heading {
			margin-bottom: $m-16;
		}
		.tablinks {
			li {
				padding-top: $m-16;
			}
			span {
				font-size: 16px;
				line-height: 1.5;
			}
		}
	}
}

.tablist {
	margin-bottom: $m-160;
	&__mobile {
		display: none;
		&__nav {
			margin-bottom: $m-32;
			overflow-y: auto;
			display: flex;
			&::-webkit-scrollbar {
				display: none;
			}
			li {
				font-size: 18px;
				transition: $transition-time $transition-type;
				margin-right: $m-32;
				&.active {
					font-weight: bold;
				}
			}
		}
		&__elements {
			li a {
				display: inline-flex;
				justify-content: flex-start;
				align-items: center;
				margin-bottom: calc(#{$m-16} - #{$m-8 / 2});
				&:after {
					opacity: 0;
					padding: 5px;
				}
				&:hover::after {
					opacity: 1;
				}
			}
		}
	}
	&__desktop {
		-moz-column-count: 4;
		-moz-column-gap: $m-24;
		-webkit-column-count: 4;
		-webkit-column-gap: $m-24;
		column-count: 4;
		column-gap: $m-24;
		li a {
			display: inline-flex;
			justify-content: flex-start;
			align-items: center;
			margin-bottom: $m-16;
			letter-spacing: 0;
			grid-gap: 0.4em;
			&:after {
				opacity: 0;
				transition: all $transition-time $transition-type;
				padding: 5px;
			}
			&:hover::after {
				opacity: 1;
			}
		}
	}
}

.tabcontent {
	display: none;
	@include mobile {
		overflow: hidden;
	}
	&.active {
		display: grid;
	}
	&__col,
	&__col {
		&-2,
		&-3,
		&-about {
			display: grid;
		}
		&-2 {
			grid-template-columns: repeat(2, 1fr);
			gap: $m-32;
		}
		&-3 {
			grid-template-columns: repeat(3, 1fr);
			gap: $m-104 $m-32;
			align-items: stretch;
		}
		&-about {
			grid-template-columns: 6fr 1fr 5fr;
			gap: $m-32;
		}
	}
	&__single {
		display: grid;
		align-items: flex-start;
		grid-template-areas:
			'content sitebar'
			'video__wrapper video__wrapper';
		grid-template-columns: 6fr 4fr;
		.content {
			grid-area: content;
			margin-right: 16.66%;
		}
		.sitebar {
			grid-area: sitebar;
			@include tablet-xl-desktop {
				position: sticky;
				top: 20vh;
			}
		}
		.video__wrapper {
			grid-area: video__wrapper;
		}
	}

	&__item {
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		@include hover {
			svg {
				opacity: 0;
			}
			&:hover {
				svg {
					opacity: 1;
					stroke: var(--textColor);
				}
			}
		}
		&.photo-anim:not(.photo-anim-on) {
			img {
				height: 50%;
			}
		}
		&__img {
			display: flex;
			justify-content: center;
			.figure-container {
				display: flex;
				justify-content: center;
				align-items: center;
			}
			figure {
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				grid-template-rows: repeat(2, 1fr);
				max-height: 100%;
				img {
					object-fit: cover;
					grid-column: 1 / -1;
					grid-row: 1 / -1;
				}
			}
		}
		&__info {
			margin: $m-32 0 auto;
			padding-bottom: $m-32;
			h1,
			h2,
			h3,
			h4,
			h5,
			h6 {
				margin-bottom: $m-8;
			}
			p:not(:last-child) {
				margin-bottom: calc(#{$m-8 / 2});
			}
			&-link {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-between;
				margin-bottom: $m-8;
				h1,
				h2,
				h3,
				h4,
				h5,
				h6 {
					margin-right: $m-8;
					margin-bottom: 0;
				}
				svg {
					transition: all $transition-time $transition-type;
				}
			}
			&-artist {
				margin: $m-32 0 -12px; // FIXME: negative margin
			}
		}
		&::after {
			content: '';
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 2px;
			background: var(--textColor);
			transition: $defaultTransition;
			transform-origin: left;
		}
	}
}

// $screen-xxl: 1400px;
@include mobile-desktop-xxl {
	.tablinks {
		margin-bottom: $m-64;
		li {
			padding-bottom: $m-16;
			margin-right: $m-32;
		}
	}
	.tablist {
		margin-bottom: $m-104;
	}
	.tabcontent {
		&__col-3 {
			gap: $m-80 $m-32;
		}
		&__item {
			&__info {
				margin: $m-16 0 auto;
				padding-bottom: $m-16;
				&-artist {
					margin: $m-32 0 0;
				}
			}
		}
	}
}
// $screen-xl: 1200px;
@include mobile-tablet-xl {
}
// $screen-xhl: 1080px;
@include mobile-tablet-xhl {
}
// $screen-lg: 992px;
@include mobile-tablet {
}
// $screen-md: 768px;
@include mobile-md {
	.tablist {
		margin-bottom: $m-80;
		&__desktop {
			-moz-column-count: 2;
			-moz-column-gap: $m-16;
			-webkit-column-count: 2;
			-webkit-column-gap: $m-16;
			column-count: 2;
			column-gap: $m-16;
			li a {
				margin-bottom: calc(#{$m-16} - #{$m-8 / 2});
			}
		}
	}
	.tabcontent {
		&__col-2 {
			grid-template-columns: 1fr;
			gap: $m-80;
		}
		&__col-3 {
			grid-template-columns: repeat(2, 1fr);
		}
		&__col-about {
			grid-template-columns: 1fr;
			.languages {
				order: 1;
			}
			.slider {
				order: 2;
			}
			.about__content {
				order: 3;
			}
		}
		&__single {
			grid-template-columns: 5fr 3fr;
			gap: $m-32;
			.content {
				margin-right: 0;
			}
		}
		&__item {
			&__info {
				&-artist {
					margin: $m-16 0 0;
				}
			}
		}
	}
}
// $screen-sm: 576px;
@include mobile {
	.tablinks {
		margin-bottom: $m-32;
		li {
			margin-right: $m-16;
		}
		&.sticky {
			z-index: 99;
			width: calc(100% - 32px);
		}
	}
	.tablist {
		margin-bottom: $m-40;
		&__desktop {
			display: none;
		}
		&__mobile {
			display: block;
			&__elements {
				-moz-column-count: 1;
				-moz-column-gap: 0;
				-webkit-column-count: 1;
				-webkit-column-gap: 0;
				column-count: 1;
				column-gap: 0;
			}
		}
	}
	.tabcontent {
		// &__item__img img {
		// 	max-height: 280px;
		// }
		&__col-2 {
			gap: $m-40;
		}
		&__col-3 {
			grid-template-columns: 1fr;
			gap: $m-40;
		}
		&__single {
			grid-template-areas:
				'content'
				'video__wrapper'
				'sitebar';
			grid-template-columns: 1fr;
		}
	}
}
//---- Page Tabs & Content End ------
//-----------------------------------

.tabcontent__item {
	&__img {
		overflow: hidden;
	}
	p,
	a {
		overflow: hidden;
		span {
			display: block;
		}
	}
}

.video__about {
	span {
		transform: translateY(100%);
		transition: $defaultTransition;
	}
	&.easy-fadeNot-on {
		span {
			transform: translateY(0);
			transition: $defaultTransition;
		}
	}
}

.video__about {
	overflow: hidden;
	span {
		display: block;
	}
}
